import { PageLayout } from './components/PageLayout';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Contact } from './views/Contact';
import { Home } from './views/Home';
import { Projects } from './views/Projects';
import { Blog, BlogLayout } from './views/blog';
import { FullPost } from './views/blog/full-post';

export function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<PageLayout />}>
          <Route index element={<Home />} />
          <Route path="contact" element={<Contact />} />
          <Route path="projects" element={<Projects />} />
          <Route path="blog" element={<BlogLayout />}>
            <Route index element={<Blog />} />
            <Route path="post/:postId" element={<FullPost />} />
          </Route>
        </Route>
      </Routes>
    </Router>
  );
}
