import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../assets/logo_white_background-logo_only.png';

export const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [translucent, setTranslucent] = useState('');

  useEffect(() => {
    setTranslucent(menuOpen ? 'translucence' : '');
  }, [menuOpen]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header
      className={`header header-clear header-filled u-unselectable no-transition header-animated ${translucent}`}
    >
      <div className="header-brand">
        <div className="nav-item no-hover">
          <Link to="/">
            <img className="w-4" src={logo} alt="Ogamba Creates" />
            <h6 className="title">Ogamba Creates</h6>
          </Link>
        </div>
        <div className={`nav-item nav-btn ${menuOpen ? 'active' : ''}`}>
          <button
            type="button"
            className="btn-transparent"
            onClick={() => toggleMenu()}
          >
            <span />
            <span />
            <span />
            <span style={{ display: 'none' }} />
          </button>
        </div>
      </div>
      <div className={`header-nav ${menuOpen ? 'active' : ''}`}>
        <div className="nav-right">
          <div className="nav-item text-center">
            <Link to="/projects" onClick={() => toggleMenu()}>
              Projects
            </Link>
          </div>
          <div className="nav-item text-center">
            <Link to="/blog" onClick={() => toggleMenu()}>
              Blog
            </Link>
          </div>
          <div className="nav-item text-center">
            <Link to="/contact" onClick={() => toggleMenu()}>
              Contact Me
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
};
