import { useState } from 'react';
import { Outlet } from 'react-router-dom';

export const Projects = () => {
  const [search, setSearch] = useState('');
  return (
    <>
      <h1>Projects</h1>
      <input
        type="text"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <div className="space" />
      <Outlet />
    </>
  );
};
