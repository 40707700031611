import { Link } from 'react-router-dom';
import { Post } from 'types';

interface Props {
  post: Post;
  id: string;
}
export const PostCard = (props: Props) => {
  const displayDate = (dateString: string) => {
    const date = new Date(dateString);
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  };
  return (
    <div className="tile u-shadow-sm p-2">
      <div className="tile__container">
        <div className="tile__title m-0">{props.post.title}</div>
        <div className="tile__subtitle m-0">
          {props.post.subtitle}
          <span className="info">
            {props.post.updated
              ? `Last updated on ${displayDate(props.post.updated)}`
              : `Posted on ${displayDate(props.post.created)}`}
          </span>
        </div>
      </div>
      <div className="tile__buttons m-0">
        <Link to={`/blog/post/${props.id}`} className="btn btn-link">
          View
        </Link>
      </div>
    </div>
  );
};
