import { Link } from 'react-router-dom';
import logo from '../../assets/logo_white_background.jpg';

export const Home = () => {
  return (
    <>
      <div className="u-center">
        <img
          className="w-32"
          src={logo}
          alt="Ogamba Creates: success through future"
        />
      </div>
      <p>
        Hi! I&apos;m Charles. I am a frontend developer working for{' '}
        <a href="https://www.mhighpoint.com" className="subtitle">
          Highpoint
        </a>
        . I create web apps and websites, mostly using React and Node.JS.
      </p>
      <p>
        The most important thing I have learnt as a creative is that failure is
        a part of success. I want to share my experiences as I design, develop,
        and create things that I am interested in. You can find most of this on
        my{' '}
        <Link to="/blog" className="subtitle">
          Blog
        </Link>
        , and my project demos on the{' '}
        <Link to="/projects" className="subtitle">
          Projects
        </Link>{' '}
        page.
      </p>
    </>
  );
};
