import { useEffect, useState } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';
import { PostCard } from 'src/components/posts/post-card';
import { GetPosts, Post } from 'types';

const baseUri = 'https://api.ogamba.com/blog/posts';

export const BlogLayout = () => {
  const [posts, setPosts] = useState(new Map<string, Post>());

  useEffect(() => {
    fetch(baseUri)
      .then((res) => res.json())
      .then((res: GetPosts) => {
        const newPosts = new Map<string, Post>();
        res.data.forEach((post) => newPosts.set(post._id.toString(), post));
        setPosts(newPosts);
      })
      .catch((e) => console.log(Error(e)));
  }, []);

  return <Outlet context={[posts]} />;
};
export const Blog = () => {
  const [posts] = useOutletContext<[Map<string, Post>]>();

  return (
    <ul className="u-flex u-gap-2 no-bullets mx-0">
      {[...posts].map(([id, post]) => (
        <li key={id} className="u-basis-100p">
          <PostCard post={post} id={id} />
        </li>
      ))}
    </ul>
  );
};
