import { useOutletContext, useParams } from 'react-router-dom';
import { Post } from 'types';

export const FullPost = () => {
  const [posts] = useOutletContext<[Map<string, Post>]>();
  const { postId } = useParams();
  const post = postId ? posts.get(postId) : null;
  return post ? (
    <div dangerouslySetInnerHTML={{ __html: post.content }} />
  ) : (
    <h1>No Post found</h1>
  );
};
