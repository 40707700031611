import { Header } from './general/Header';
import { Outlet } from 'react-router-dom';

export const PageLayout = () => (
  <>
    <Header />
    <div className="px-2">
      <Outlet />
    </div>
  </>
);
